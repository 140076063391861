<template>
  <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app dark color="primary" elevate-on-scroll>
    <v-app-bar-nav-icon @click="$store.commit('toggleDrawer')"></v-app-bar-nav-icon>
    <v-toolbar-title v-text="$route.name"></v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon @click="$store.dispatch('logout')">
      <v-icon>mdi-logout</v-icon>
    </v-btn>
    <v-progress-linear :active="isLoading" :indeterminate="isLoading" color="secondary" absolute bottom></v-progress-linear>
  </v-app-bar>
</template>

<script>
export default {
  computed: {
    drawer() {
      return this.$store.state.drawer;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
};
</script>
