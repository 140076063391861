<template>
  <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
    <v-divider class="mb-4"></v-divider>
    <v-list-item outlined link to="/">
      <v-list-item-avatar>
        <v-img src="@/assets/logo.png" max-height="40" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="title"> OrderCenter </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list nav>
      <template>
        <v-list-item-group>
          <v-list-item v-for="item in items" :key="item.title" :to="item.to" color="primary" exact>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </template>
    </v-list>

    <template v-slot:append>
      <v-list nav>
        <v-list-item @click="$store.dispatch('logout')">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-title>Abmelden</v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(state) {
        this.$store.commit('setDrawer', state);
      },
    },
  },
  data: () => ({
    items: [
      {
        icon: 'mdi-cash-register',
        title: 'Shop Bestellungen',
        to: '/dashboard/list',
      },
      {
        icon: 'mdi-cash-register',
        title: 'Umsatz',
        to: '/dashboard/sales',
      },
      {
        icon: 'mdi-cash-register',
        title: 'Umsatz pro Store',
        to: '/dashboard/umsatz',
      },
      {
        icon: 'mdi-cash-multiple',
        title: 'Bezahlarten',
        to: '/dashboard/paymenttypes',
      },
      {
        icon: 'mdi-format-list-numbered',
        title: 'Bestellungen',
        to: '/dashboard/orders',
      },
    ],
  }),
};
</script>
