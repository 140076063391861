<template>
  <v-app>
    <drawer></drawer>
    <app-bar></app-bar>
    <v-main>
      <v-container class="px-4 py-0 fill-height background" fluid>
        <v-row class="fill-height">
          <v-col>
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from '@/views/dashboard/AppBar.vue';
import Drawer from '@/views/dashboard/Drawer.vue';

export default {
  components: {
    AppBar,
    Drawer,
  },
  created() {
    this.$store.dispatch('getMyStores');
  },
};
</script>
